<template>
        <section class="fieldset">
                <h3 class="h6 fieldset__title">{{header}}</h3>
                <div class="fieldset__content row">
                  <slot></slot>
                </div>
        </section>
</template>

<script>
    export default {
        props:['header']
    }
</script>

<style lang="scss" scoped>

</style>