

/** trasforma un json in una stringa dot notation */
function dotNotate(obj,target,prefix) {
    target = target || {},
    prefix = prefix || "";
  
    Object.keys(obj).forEach(function(key) {
      if ( typeof(obj[key]) === "object" && obj[key] !== null ) {
        dotNotate(obj[key],target,prefix + key + ".");
      } else {
        return target[prefix + key] = obj[key];
      }
    });
  
    return target;
}


export class SpatieQueryBuilder {

    static stringify(json_from_vue) {
      
      const obj = dotNotate(json_from_vue)
        const param = []
        Object.keys(obj).forEach(function(key) {
        if(obj[key]){
            param.push(`filter[${key}]=${encodeURIComponent(obj[key])}`);
        }    

        });

        const query = param.join("&")
        return query;
    }
}



