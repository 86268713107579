import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// # Codificare colori del thema
// https://vuetifyjs.com/en/features/theme/#custom-theme-variants
// https://betterprogramming.pub/changing-application-theme-dynamically-in-vuetify-js-c01d640699c4
// ;

// import colors from 'vuetify/lib/util/colors'

// const options = {
//     theme: {
//       themes: {
//         light: {
//           primary: '#3f51b5',
//         //   primary: '#009688',
//           secondary: '#b0bec5',
//           accent: '#8c9eff',
//           error: '#b71c1c',
//         },
//         tertiary: colors.pink.base,
//       },
//     },
// }
let options = {}
// alert(process.env.VUE_APP_API_URL);
// switch (window.location.hostname) {
switch (process.env.VUE_APP_API_URL) {
    case 'https://teo.sarassoroberto.it':
        options = {
            theme: {
                themes: {
                    light: {
                        primary: '#adb53f',
                        danger: '#ff0000',
                        secondary: '#3e3e3e',
                        warning: '#FFA014',
                        action: 'orange'
                    },
                    dark: {
                        primary: '#adb53f',
                        danger: '#ff0000',
                        secondary: '#3e3e3e',
                        warning: '#FFA014',
                        action: 'orange'
                    }

                },
            },
        }
        break;
    case 'http://161.97.76.57':
    case 'http://api-test-teo.aurumib.it':
        options = {
            theme: {
                themes: {
                    light: {
                        primary: '#009688',
                        danger: '#ff0000',
                    }
                },
            },
        }
        break;
    case 'http://staging-teo.aurumib.it':
        options = {
            theme: {
                themes: {
                    light: {
                        primary: '#5d3a6f',
                        danger: '#ff0000',

                    }
                },
            },
        }
        break;

    default:
        break;
}


export default new Vuetify(options);
