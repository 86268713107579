<template>
  <v-app id="App" :src="{ background: require('./assets/bg-aurum.png') }">
    <router-view></router-view>
  </v-app>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
