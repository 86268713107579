<template>
  <v-card id="SidebarNav" data-cy="SidebarNav" height="400" width="256" class="mx-auto">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> Menu Rapido </v-list-item-title>
        <v-list-item-subtitle>
          funzionalità principali utente
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <!-- <v-divider></v-divider> -->
    <!-- <pre>{{menuLaterale}} </pre> -->
    <v-list data-cy="menuLaterale" dense nav>
      <v-list-item v-for="item in menuLaterale" :key="item.title" :data-cy="item.show_polizze" :to="item.route" link>

        <v-list-item-icon>
          <v-icon>{{ item.icon }} </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>

      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "sidebar-nav",
  data() {
    return {
      // TODO Vedi su TRELLO
      // manu: generaMenu(['polizza_index','cliente_index']),
      right: null,
    };
  },
  computed: {
    menuLaterale() {
      let menu = [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          can: "show_dashboard",
          route: {
            name: "dashboard",
          },
        },
        {
          title: "Polizze",
          // TODO | IDEA | ROUTE | Si potrebbero prendere gli meta-dati attraverso il name della root così non devo ripetere qui icona eccetera
          // TODO | Mettere anche questo nello store
          icon: "mdi-view-dashboard",
          can: "show_polizze",
          route: {
            name: "polizza_index",
          },
        },
        {
          title: "Fornitori",
          icon: "mdi-view-dashboard",
          can: "show_fornitori",
          route: {
            name: "compagnia_index",
          },
        },
        {
          title: "Collaboratori",
          icon: "mdi-person",
          can: "show_collaboratori",
          route: {
            name: "collaboratore_index",
          },
        },
        {
          title: "Clienti",
          icon: "mdi-image",
          can: "show_suoi_clienti",
          route: {
            name: "cliente_index",
          },
        },
        {
          title: "Auto Storiche",
          icon: "mdi-image",
          can: "show_auto_storiche",
          route: {
            name: "auto_storiche_index",
          },
        },

        {
          title: "Quotazioni",
          icon: "mdi-image",
          can: "show_sue_quotazioni",
          route: {
            name: "quotazione_index",
          },
        },
      ]

      return menu.filter(i => this.can(i.can))
    }
  }
};
</script>
<style>
.v-list-item__icon {
  margin-right: 5.4px !important;
}
</style>