<template>
  <div :id="id">
   
    <label class="my-2" :for="label">{{ label }}</label>
    <!-- v-model="formData.data_operazione" -->
    <date-picker
      class="w-100"
      v-bind="$attrs"
      v-on="$listeners"
      autocomplete="off"
      :data-cy="id"
      dense
      value-type="YYYY-MM-DD"
      format="DD/MM/YYYY"
      >
     
      <!-- SLOT EREDITATI -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </date-picker>
    <invalid-feedback :errors="errors" />
  </div>
</template>

<script>
  import DatePicker from "vue2-datepicker";
import InvalidFeedback from '../InvalidFeedback.vue';

  export default {
    props: ["label", "id","errors","error-messages","error"],
   
    components: {
      DatePicker,
        InvalidFeedback
    },
      methods: {
    handleChange(date) {
      console.log('Data cambiata:', date);
    },
    handleOpen() {
      console.log('DatePicker aperto');
    },
    handleClose() {
      console.log('DatePicker chiuso');
    }
  }
  };
</script>

<style lang="scss" scoped></style>
