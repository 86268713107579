<template>
    <v-container fluid :class="containerPadding">
     <table-component
       fixed-header
       :id="model.toLowerCase()+'_elenco'"
       :resource="polizza_index"
       :config="model.toLowerCase()+'-table'"
       :toolbar-title="models"
       :loading="loading"
       loading-text="attendi un attimo"
       :items-per-page="itemsPerPage"
       >
     </table-component>
   </v-container>
</template>
<script>
// import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import TableComponent from '../ui/TableComponent.vue';

const model = 'Titolo'
const models = 'Titoli'

export default {
  //  name: model.toLowerCase+"-page",
   components: {TableComponent  },
   data() {
       return {
           model:model,
           models:models,
           /** @var selectedItems elementi selezionati della tabella passati tramite un evento */
           selectedItems: []
       };
   },
   mounted() {
      this.$store.dispatch("TitoloModule/index"); 
      
   },
   methods: {

      
     
   },
   computed: {
     ...mapFields("TitoloModule", [
       "ui",
       "titolo_index",
       "loading"
     ]),
     // tableDataset() {
     //   //console.log("tableDataset",this.$store.state[models.toLowerCase()]);
     //   return this.$store.state.[models.toLowerCase()];
     // },
     // loading() {
     //   return this.$store.state.loading;
     // },
   }
}
</script>