<template>
  <v-container fuild
               fill-height>
    <v-layout justify-center>
      <v-flex xs12
              sm8
              md4>
        <!-- login view -->
        <v-card v-if="true">
           {{ env  }} 
          <v-toolbar color="primary"
                     dark>
            <v-toolbar-title>Accedi con le tue credenziali</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <!-- login form -->
            <v-form>
              <v-text-field v-model='formData.email'
                            outlined
                            dense
                            autocomplete="false"
                            data-cy='email'
                            
                            label='email'
                            type='text'
                            required>
              </v-text-field>
              <v-text-field v-model='formData.password'
                            outlined
                            dense
                            autocomplete="false"
                            data-cy='password'
                            label='Password'
                            type='password'
                            required>
              </v-text-field>
              
              <InvalidFeedback :errors="errors.auth" />
             
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn color="primary"
                       data-cy='btn-accedi'
                       dark
                       @click='loginClicked'>
                  Accedi
                </v-btn>
              </v-card-actions>

            </v-form>
            <!-- login form -->
          </v-card-text>
        </v-card>
        <v-card v-if="false">
          <v-toolbar color="red"
                     dark>
            <v-toolbar-title>Sito in aggiornamento</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <!-- login form -->
            <v-form>
              <v-text-field v-model='formData.email'
                            outlined
                            dense
                            autocomplete="false"
                            data-cy='email'
                            
                            label='email'
                            type='text'
                            required>
              </v-text-field>
              <v-text-field v-model='formData.password'
                            outlined
                            dense
                            autocomplete="false"
                            data-cy='password'
                            label='Password'
                            type='password'
                            required>
              </v-text-field>
              
              <InvalidFeedback :errors="errors.auth" />
             
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn color="primary"
                       data-cy='btn-accedi'
                       disabled
                       dark
                       @click='loginClicked'>
                  Accedi
                </v-btn>
              </v-card-actions>

            </v-form>
            <!-- login form -->
          </v-card-text>
        </v-card>
        <!-- end login view -->
      </v-flex>
    </v-layout>
    <v-footer 
     color="primary lighten-1"  
     fixed
     padless>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ env  }} 
      {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
    </v-col>
  </v-footer>
  </v-container>
</template>

<script>
// import axios from 'axios';
import InvalidFeedback from "../ui/InvalidFeedback.vue";

export default {
  name: "LogInPage",
  components:{
    InvalidFeedback
  },
      computed: {
        // user() {
        //     //return window.sessionStorage['roles'];
        //     return this.$store.state.user;
        // },
        // notify() {
        //     return this.$store.state.notify;
        // },
        env() {
            return process.env.VUE_APP_API_URL;
        },
        errors() {
        return this.$store.state.authErrors || {};
      }
    },
  data(){
    return {
      formData:{
       
      }
    }
  },
  methods: {
    loginClicked() {
      this.$store.dispatch('ACTION_LOGIN',this.formData)
    },
    logOutClicked() {
      this.$store.dispatch('ACTION_LOGOUT',this.formData)
    }
  },

}
</script>

<style lang="scss"
 scoped>

</style>