

// Funzione per rimuovere voci headers basate sui valori
export function removeHeadersByValues(headers, valuesToRemove) {
  return headers.filter(header => !valuesToRemove.includes(header.value));
}

// Funzione per aggiungere headers
export function addHeaders(headers, newHeaders, afterValue = null) {
  let index = -1;

  // Se afterValue è specificato, trova l'indice dell'elemento
  if (afterValue !== null) {
    index = headers.findIndex(header => header.value === afterValue);
  }

  if (index !== -1) {
    // Aggiungi dopo l'elemento specificato
    headers.splice(index + 1, 0, ...newHeaders);
  } else {
    // Aggiungi alla fine se afterValue non è specificato o non trovato
    headers.push(...newHeaders);
  }

  return headers;
}
