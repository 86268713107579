import axios from "axios";
import { router } from "../main";

export const Api = axios.create();

/**
  https://github.com/garethredfern/laravel-vue/blob/v1.2.1/src/services/API.js
 */
Api.interceptors.request.use(function (config) {
    // const token = store.getState().session.token;
    const token = window.sessionStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Cache-Control"] = `no-cache`;
    config.headers["Expires"] = `0`; 
    config.headers["Content-Type"] = "application/json";
    // config.headers["Accept"] = "application/json, text/html";
    config.headers["Accept"] = "application/json";
    return config;
});
 
/*
 * Add a response interceptor
 */
Api.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            (error.response.status === 401 || error.response.status === 419)
        ) {
            // alert("TODO:dispach logout")
            // store.dispatch("auth/logout");
            // errorCallback(error)
        }
        return Promise.reject(error);
    }
);

export function errorCallback(error, subject = "") {
    // || error.response.status == 403
    if (error.response && error.response.status == 403) {
        // alert(error.response.status);
        // TODO: Validazione comune ?
    }
    if (error.response && error.response.status == 401) {
        // alert("errorCallback " + error.response.status)
        window.sessionStorage.removeItem("token");
        router.push("/login");
    }

    if (error.response && error.response.status == 404) {
        alert(subject + " risorsa non trovata ");
    }

    // if(error){
    //    alert(error.code)
    // }
}
