<template>
    <v-card>
        <v-card-text v-if="alert.showAlert">
            <v-alert :type="alert.type" :title="alert.title">
                <h5>{{ alert.title }}</h5>{{ alert.text }}
            </v-alert>
        </v-card-text>
        <span v-if="!datiInviati">
            <v-card-title>Carica i tuoi documenti</v-card-title>
            <v-card-text class="text--primary">
                <div v-for="upload in uploads" :key="upload.collection">
                    <v-card>
                        <v-card-title>{{ upload.title }}
                            <v-divider vertical class="mx-2"></v-divider>
                            <upload-dialog :collection="upload.collection" :model="upload.model"
                                v-if="upload.items.length < 3" :id="upload.id" @success="onAddDocumentSuccessQ"
                                @error="onAddDocumentErrorQ($event)"></upload-dialog>
                        </v-card-title>
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left prima-colonna">Documento</th>
                                            <th class="text-left seconda-colonna">Categoria</th>
                                            <th class="text-left terza-colonna">Download</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="item in upload.items" :key="item.id">
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.collection_name }}</td>
                                            <td>
                                                <a target="_blank" :href="documentUrl(item, 'quotazione')">Download</a>
                                            </td>
                                        </tr>


                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                    <v-divider></v-divider>
                </div>
                <v-card v-for="item in q.veicoli_storici" :key="item.id">
                    <v-card-title>Libretto {{ item.auto_storica.marca }} {{ item.auto_storica.modello }}
                        <v-divider vertical class="mx-2"></v-divider>
                        {{ item.media.count }}
                        <upload-dialog collection="libretto" model="Veicolo" :id="item.id" v-if="item.media.length < 3"
                            @success="onAddDocumentSuccessV($event, item)"
                            @error="onAddDocumentErrorV($event)"></upload-dialog>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left prima-colonna">Documento</th>
                                        <th class="text-left seconda-colonna">Categoria</th>
                                        <th class="text-left terza-colonna">Download</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="m in item.media" :key="m.id">
                                        <td>{{ m.name }}</td>
                                        <td>{{ m.collection_name }}</td>
                                        <td>
                                            <a target="_blank" :href="documentUrl(m)">Download</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <section>
                    <v-dialog max-width="600px" v-model="terminaDialog">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                Termina Caricamento </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5">Terminare il caricamento dei documenti?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn small color="blue darken-1" text>Cancel</v-btn>
                                <v-btn small color="blue darken-1" text @click="terminaCaricamento">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </section>
            </v-card-actions>
        </span>
    </v-card>
</template>
  
<script>
import UploadDialog from './UploadDialog.vue';
import axios from 'axios';

export default {
    components: {
        UploadDialog
    },
    data() {
        return {
            terminaDialog: false,
            datiInviati: false,
            alert: {
                type: "",
                message: "",
                title: "",
                showAlert: false
            },
            errors: []
        };
    },
    props: {
        quotazione: {
            type: Object,
            required: true
        }
    },
    methods: {
        onAddDocumentSuccessQ(response) {
            const newMedia = response.data
            console.log(newMedia)
            this.q.media.push(newMedia)
        },
        onAddDocumentSuccessV(event, item) {
            const newMedia = event.data
            console.log(newMedia)
            item.media.push(newMedia)
        },
        onAddDocumentErrorQ(event) {
            console.log(event)
            this.alert.type = "error";
            this.alert.title = "Errore"
            this.alert.text = "Non hai specificato un file da caricare"
            this.alert.showAlert = true
            this.terminaDialog = false
        },
        onAddDocumentErrorV(event) {
            console.log(event)
            this.alert.type = "error";
            this.alert.title = "Errore"
            this.alert.text = "Non hai specificato un file da caricare"
            this.alert.showAlert = true
            this.terminaDialog = false
        },
        documentUrl(item) {
            return (
                process.env.VUE_APP_API_URL +
                "/api/quotazioni/getDocument/" +
                item.collection_name +
                "/" +
                item.id
            );
        },
        async terminaCaricamento() {
            await axios
                .post(
                    process.env.VUE_APP_API_URL +
                    "/api/cliente/quotazione/terminaCaricamento/" + this.quotazione.uuid,
                )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.alert.type = "success";
                        this.alert.title = "Procedimento completato con successo"
                        this.alert.text = "Riceverai una mail riepilogativa con le indicazioni per il proseguimento della pratica."
                        this.alert.showAlert = true
                        this.datiInviati = true
                    }
                    console.log(this.response.data.message);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data.message);
                        this.alert.type = "error";
                        this.alert.title = "Errore"
                        this.alert.text = error.response.data.message
                        this.alert.showAlert = true
                        this.terminaDialog = false
                    }
                });
        }
    },
    created: function () {
        this.q = this.quotazione

    },
    computed: {
        uploads() {
            return [
                {
                    title: "Carta d'Identità",
                    collection: "carta_identita",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter(item => {
                        return item.collection_name.toLowerCase().includes("carta_identita");
                    })
                },
                {
                    title: "Patente",
                    collection: "patente",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter(item => {
                        return item.collection_name.toLowerCase().includes("patente");
                    })
                },
                {
                    title: "Codice Fiscale",
                    collection: "codice_fiscale",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter(item => {
                        return item.collection_name.toLowerCase().includes("codice_fiscale");
                    })
                },
                {
                    title: "Tessera Club",
                    collection: "tessera_club",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter(item => {
                        return item.collection_name.toLowerCase().includes("tessera_club");
                    })
                }
            ]
        }
    }
};
</script>

<style>
.prima-colonna {
    width: 50%;
}

.seconda-colonna {
    width: 40%;
}

.terza-colonna {
    width: 10%;
}
</style>