// aniaUpdateModule.js
import { Api } from '@/http_tools/axiosModule';
import axios from 'axios';

export async function updateAniaData(veicolo) {
    // loadingState.value = true;
    try {
        const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/api/veicolo_update_dati_ania/${veicolo.id}/${veicolo.targa}/${veicolo.tipologia_ania}`
        );

        veicolo.json = JSON.stringify(response.data);
        veicolo.sivi_tax_code = response.data.ResponseSivi[0].taxCode
        veicolo.atrc_contract_expiration_date = response.data.ResponseAtrcMain[0].contractExpirationDate
        veicolo.atrc_beneficiary_first_name = response.data.ResponseAtrcMain[0].beneficiaryFirstName
        veicolo.atrc_beneficiary_last_name = response.data.ResponseAtrcMain[0].beneficiaryLastName
        veicolo.sivi_registration_date = response.data.ResponseSivi[0].registrationDate
        return veicolo

    } catch (error) {
        console.error('Errore durante l\'aggiornamento dei dati ANIA:', error);
    } finally {
        // loadingState.value = false;
    }
}


export async function ottieniDatiAnia(veicolo) {
    try {
        const response = await Api.post(
            `${process.env.VUE_APP_API_URL}/api/ciccio/${veicolo.targa}/${veicolo.tipologia_ania}`
        )

        return {...veicolo,...response.data}
    } catch (error) {
        console.error('Error updating ANIA data:', error);
        throw error; 
    } 
}

export function assegnaAventeDirittoSivi(veicolo, quotazione) {
    console.log("assegnaAventeDirittoSivi", veicolo, quotazione);
    if (veicolo.sivi_tax_code) {
        if (veicolo.sivi_tax_code == quotazione.cfisc) {
            veicolo.avente_diritto_cognome = quotazione.cognome;
            veicolo.avente_diritto_nome = quotazione.nome;
            veicolo.avente_diritto_codice_fiscale_iva = quotazione.cfisc;
            veicolo.active_sivi_tax_code = true;
            veicolo.alert = "il codice fiscale corrisponde a quello del contraente";
        } else {
            veicolo.avente_diritto_cognome = "";
            veicolo.avente_diritto_nome = "";
            veicolo.avente_diritto_codice_fiscale_iva = veicolo.sivi_tax_code;
            veicolo.alert = "il codice fiscale indicato dal SIVI non corrisponde a quello del contraente";
        }
    } else {
        veicolo.alert = "non sono presenti informazioni sul SIVI";
    }
    return veicolo;
}

export function assegnaAventeDirittoAtrc(veicolo, quotazione) {
    if (veicolo.atrc_beneficiary_tax_code) {
        if (veicolo.atrc_beneficiary_tax_code == quotazione.cfisc) {
            veicolo.avente_diritto_cognome = quotazione.cognome;
            veicolo.avente_diritto_nome = quotazione.nome;
            veicolo.avente_diritto_codice_fiscale_iva = quotazione.cfisc;
            veicolo.active_atrc_beneficiary_tax_code = true;
            veicolo.alert = "il codice fiscale dell' avente diritto coincide con il contraente indicato dal sivi";
        } else {
            veicolo.avente_diritto_cognome = veicolo.atrc_beneficiary_last_name;
            veicolo.avente_diritto_nome = veicolo.atrc_beneficiary_first_name;
            veicolo.avente_diritto_codice_fiscale_iva = veicolo.atrc_beneficiary_tax_code;
            veicolo.alert = "il codice fiscale dell' avente diritto è stato preso dall'attestato di rischio e non coincide con il contraente";
        }
    } else {
        veicolo.alert = "non sono presenti informazioni sul ATRC (attestato di rischio)";
    }
    return veicolo;
}

export function assegnaAventeDirittoContraente(veicolo, quotazione) {
    veicolo.avente_diritto_cognome = quotazione.cognome;
    veicolo.avente_diritto_nome = quotazione.nome;
    veicolo.avente_diritto_codice_fiscale_iva = quotazione.cfisc;
    veicolo.active_teo_cliente = true;
    veicolo.alert = "Il contraente è l'avente diritto";
    return veicolo;
}

export function clearAventeDiritto(veicolo) {
    veicolo.avente_diritto_cognome = "";
    veicolo.avente_diritto_nome = "";
    veicolo.avente_diritto_codice_fiscale_iva = "";
    veicolo.active_teo_cliente = false;
    veicolo.alert = "";
    return veicolo;
}

export function assegnaQuintaliDaSivi(veicolo) {
    veicolo.alert = "Quintali assegnati dal sivi";
    veicolo.quintali = veicolo.sivi_vehicle_weight;
    return veicolo;
}

export function assegnaQuintaliDaLibretto(veicolo) {
    // Implementa questa funzione come necessario
    return veicolo;
}

export function updateQuotazionePerEmissione(veicolo) {
    // Implementa questa funzione come necessario
    return veicolo;
}
