<template>
  <section id="PolizzaForm" class="model__form">
    <v-toolbar dense>
      <v-toolbar-title>
        <v-card-title v-if="polizza" class="h4" primary-title>
          <span v-if="!polizza.id">Dati Polizza</span>
          <span v-if="polizza.id">{{
           "N°" + polizza.polizza_numero + " " + (polizza.cliente.label || "") +" "+ ( polizza.mandato_prodotto.label ||"")
          }}</span>
        </v-card-title>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn v-if="ui.mode === 'EDIT_MODE'" color="primary" @click="edit(polizza)"
          >Aggiorna</v-btn
        >

        <v-btn
          v-if="ui.mode === 'ADD_NEW_MODE'"
          color="primary"
          @click="aggiungi(polizza)"
          >Aggiungi</v-btn
        >
        <v-btn color="primary" @click="annulla()">Annulla</v-btn>
        <!-- <v-btn color="indigo" dark>Link Three</v-btn> -->
      </v-toolbar-items>

      <!-- <CardAction
        v-if="false"
        :sezione-attiva="$store.state.sezioneAttiva"
        v-model="cliente.attivo"
        @on-annulla="annulla()"
        @on-aggiorna="edit(cliente)"
        @on-aggiungi="store(cliente)">
      </CardAction> -->
    </v-toolbar>

    <v-card background-color="white" :loading="loading">
      <v-card-text>
        <!-- RICERCA PRODOTTO -->
        <v-row align="center" justify="start">
          <v-col>
            <autocomplete
              :loading="loading"
              clearable
              return-object
              class="flex-grow-1"
              prepend-icon="mdi-account"
              label="Collaboratore"
              item-value="id"
              item-text="label"
              v-model="polizza.collaboratore"
              dense
              resource="/api/autocomplete/collaboratore"
              :error-messages="errors.collaboratore_id"
              :error="
                errors.collaboratore_id && errors.collaboratore_id.length > 0
              " />
          </v-col>

          <!-- cliente_id -->
          <v-col>
            <autocomplete
              :loading="loading"
              :disabled="loading"
              clearable
              prepend-icon="mdi-account"
              label="Contraente"
              item-value="id"
              item-text="label"
              v-model="polizza.cliente"
              return-object
              dense
              resource="/api/autocomplete/cliente"
              :error-messages="errors.cliente_id"
              :error="errors.cliente_id && errors.cliente_id.length > 0">
            </autocomplete>
          </v-col>

          <!-- Ramo -->
          <v-col>
            <autocomplete
              :loading="loading"
              clearable
              return-object
              label="Ramo"
              dense
              v-model="mandato_prodotto_filter.ramo"
              @change="updateRicercaMandatoProdottoPolizza"
              item-value="id"
              item-text="label"
              resource="/api/autocomplete/ramo" />
          </v-col>
          <!-- COMPAGNIA CONTRATTUALE -->
          <v-col>
            <autocomplete
              :loading="loading"
              clearable
              return-object
              class="flex-grow-1"
              prepend-icon="mdi-document"
              label="Compagnia Contrattuale"
              item-value="id"
              item-text="label"
              @change="updateRicercaMandatoProdottoPolizza"
              v-model="mandato_prodotto_filter.compagnia_contrattuale"
              dense
              :error-messages="errors.compagnia_contrattuale_id"
              :error="errors.compagnia_contrattuale_id && errors.compagnia_contrattuale_id.length > 0"
              resource="/api/autocomplete/compagnia_contrattuale">
            </autocomplete>
          </v-col>

          <v-col>
            <autocomplete
              :loading="loading"
              clearable
              return-object
              class="flex-grow-1"
              prepend-icon="mdi-document"
              label="Fornitore"
              item-value="id"
              item-text="label"
              @change="updateRicercaMandatoProdottoPolizza"
              v-model="mandato_prodotto_filter.fornitore"
              dense
              :error-messages="errors.fornitore_id"
              :error="errors.fornitore_id && errors.fornitore_id.length > 0"
              resource="/api/autocomplete/fornitore">
            </autocomplete>
          </v-col>

          <!-- return-object="true" -->
          <v-col>
            <v-autocomplete
            return-object
              :loading="loading"
              clearable
              class="flex-grow-1"
              prepend-icon="mdi-document"
              label="Prodotto"
              item-value="id"
              item-text="label"
              v-model="polizza.mandato_prodotto"
              :error-messages="errors.mandato_prodotto_id"
              :error="errors.mandato_prodotto_id && errors.mandato_prodotto_id.length > 0"
              dense
              :items="prodotti">
            </v-autocomplete>
          </v-col>
        </v-row>

        <!-- DATI POLIZZA -->
        <v-row align="center" justify="start">
          <v-col>
            <v-text-field
              id="polizza_numero"
              data-cy="polizza_numero"
              v-model="polizza.polizza_numero"
              dense
              type="text"
              :error-messages="errors.polizza_numero"
              :error="errors.polizza_numero && errors.polizza_numero.length > 0"
              label="Numero Polizza"></v-text-field>
          </v-col>
          <v-col>
            <teo-date-picker
              v-model="polizza.data_entrata_in_effetto"
              label="Entrata In Effetto"
              :errors="errors.data_entrata_in_effetto"

              />
          </v-col>
                    <!-- frazionamento -->
                    <v-col>
            <v-autocomplete
              label="Frazionamento"
              
              dense
              clearable
              :autocomplete="false"
              item-value="slug"
              item-text="label"
              return-object
              v-model="polizza.frazionamento"
              :items="frazionamenti" 
              @change="updateFrazionameto()"
              :error-messages="errors.frazionamento_slug"
              :error="
                errors.frazionamento_slug && errors.frazionamento_slug.length == 0
              "
              />
          </v-col>
          <v-col>
            <teo-date-picker 
                  label="Scadenza" 
                  v-model="polizza.data_scadenza" 
                  :errors="errors.data_scadenza" 
                  />
          </v-col>

          <v-col>
            <teo-date-picker
              v-model="polizza.data_scadenza_rata"
             
              label="Scadenza Rata" />
          </v-col>

          <!-- tacito_rinnovo -->
          <v-col>
            <!-- #error-messages -->
            <v-checkbox
              label="Tacito Rinnovo"
              id="tacito_rinnovo"
              @input="$emit('input', polizza)"
              v-model="polizza.tacito_rinnovo"
              data-cy="tacito_rinnovo"
              dense
              color="primary"
              :error-messages="errors.tacito_rinnovo"
              :error="
                errors.tacito_rinnovo && errors.tacito_rinnovo.length > 0
              " />
          </v-col>
        </v-row>

        <!-- IMPORTI E PREMI  -->
        <v-row align="center" justify="start">
          <!-- totale_premio_imponibile -->
          <v-col>
            <text-formatted
              label="Imponibile (Totale netto)"
              id="totale_premio_imponibile"
              data-cy="totale_premio_imponibile"
              @change="getCalcoloTotalePremioLordo"
              @focus="$event.target.select()"
              v-model="polizza.totale_premio_imponibile"
              type="number"
              dense
              :error-messages="errors.totale_premio_imponibile"
              :error="
                errors.totale_premio_imponibile &&
                errors.totale_premio_imponibile.length > 0
              ">
            </text-formatted>
          </v-col>

          <v-col>
            <text-formatted
              label="Diritti"
              @change="getCalcoloTotalePremioLordo"
              @focus="$event.target.select()"
              v-model.trim="polizza.diritti"
              dense
              color="primary"
              :error="errors.diritti && errors.diritti.length > 0"
              :error-messages="errors.diritti" />
          </v-col>
          <v-col>
            <text-formatted
            @change="getCalcoloTotalePremioLordo"
            @focus="$event.target.select()"
              label="Spese di Gestione"
              v-model.trim="polizza.spese"
              dense
              color="primary"
              :error="errors.spese && errors.spese.length > 0"
              :error-messages="errors.spese" />
          </v-col>
          <!-- totale_premio_lordo -->
          <v-col>
            <text-formatted
              id="totale_premio_imposte"
              @change="getCalcoloTotalePremioLordo"
              @focus="$event.target.select()"
              data-cy="totale_premio_imposte"
              v-model="polizza.totale_premio_imposte"
              type="number"
              dense
              :error-messages="errors.totale_premio_imposte"
              :error="
                errors.totale_premio_imposte &&
                errors.totale_premio_imposte.length > 0
              "
              
              label="Imposte">
            </text-formatted>
          </v-col>

          <v-col>
        
            <text-formatted
              label="Competenze"
              v-model.trim="polizza.competenze"
              dense
              color="primary"
              @change="getCalcoloTotalePremioLordo"
              @focus="$event.target.select()"
              :error="errors.competenze && errors.competenze.length > 0"
              :error-messages="errors.competenze" />
          
          </v-col>
          <!-- totale_premio_lordo -->
          <v-col>
            <text-formatted
              id="totale_premio_lordo"
              data-cy="totale_premio_lordo"
              v-model="polizza.totale_premio_lordo"
              @change="getCalcoloTotalePremioLordo"
              @focus="$event.target.select()"
              type="number"
              :readonly="true"
              dense
       
              :error-messages="errors.totale_premio_lordo"
              :error="
                errors.totale_premio_lordo &&
                errors.totale_premio_lordo.length > 0
              "
              label="Premio Lordo">


            </text-formatted>
          </v-col>
          
          <!-- tipo_pagamento -->
          <v-col>
            <!-- <v-text-field
              id="tipo_pagamento"
              data-cy="tipo_pagamento"
              dense
              type="text"
              :error-messages="errors.tipo_pagamento"
              :error="errors.tipo_pagamento && errors.tipo_pagamento.length > 0"
              label="Tipo Pagamento"></v-text-field> -->
          </v-col>
        </v-row>

        <!-- <v-row align="center" justify="start"> -->
          <!-- 
          // TODO: ACCESSORI MOMENTANEAMENTE ELIMINATI  
          <v-col >
            <text-formatted
              label="Accessori"
              v-model.trim="polizza.accessori"
              dense
              color="primary"
              :error="errors.accessori && errors.accessori.length > 0"
              :error-messages="errors.accessori" />
          </v-col> -->


        <!-- </v-row> -->

        <v-row v-if="false">
          <v-col>
            <v-text-field
              id="numero_polizza_sostituita"
              data-cy="numero_polizza_sostituita"
              v-model="polizza.numero_polizza_sostituita"
              dense
              type="text"
              :error-messages="errors.numero_polizza_sostituita"
              :error="
                errors.numero_polizza_sostituita &&
                errors.numero_polizza_sostituita.length > 0
              "
              label="Numero Polizza Sostituita"></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="false">
          <v-col class="polizza__sx">
            <section v-if="polizza" class="v-main__wrap">
              <!--- CARATTERISTICHE POLIZZA TAB -->

              <!-- :height = "smallTab()" -->
              <VTabs
                height="2rem"
                class="m-0 p-0 mt-3"
                v-model="tabs_caratteristiche_polizza"
                color="primary"
                slider-color="primary">
                <VTab ripple>
                  <span v-if="mandato_prodotto_filter.ramo.id == 5"> RCA </span>
                  <span v-if="mandato_prodotto_filter.ramo.id == 1">
                    MULTIRISCHI
                  </span>
                </VTab>

                <VTabItem>
                  <VCard flat>
                    <VCardText>
                      <!-- {{ mandato_prodotto_filter }} -->
                      <div
                        class="dati"
                        v-if="true || mandato_prodotto_filter.ramo.id == 5">
                        <table class="">
                          <tr>
                            <td>
                              <v-checkbox
                                dense
                                label="Guida Esperta"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox
                                dense
                                label="Guida Esclusiva"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox
                                dense
                                label="Guida Cognugi"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox
                                dense
                                label="Antifurto Satellitare"></v-checkbox>
                            </td>
                          </tr>
                          <tr>
                            <td><v-checkbox dense label="ARD"></v-checkbox></td>

                            <td>
                              <v-checkbox
                                dense
                                label="Iscrizione ASI"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox
                                dense
                                label="Iscrizione FMI"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox
                                dense
                                label="Cessione Contraenza"></v-checkbox>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div
                        class="dati"
                        v-if="mandato_prodotto_filter.ramo.id == 1">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Quisquam, eius dicta nulla a obcaecati earum
                        praesentium beatae dolores velit dolor harum dolorum
                        possimus qui quidem deleniti soluta repellat voluptas
                        eum!
                      </div>
                    </VCardText>
                  </VCard>
                </VTabItem>

                <v-tab> Convenzioni </v-tab>
                <v-tab-item>
                  <h6>Convenzioni</h6>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Temporibus dolorem laborum vel, deserunt, consequuntur sit
                  pariatur commodi voluptatibus repellendus vero accusamus, quo
                  sunt officiis ut cupiditate sed nulla vitae nobis?
                </v-tab-item>
              </VTabs>
            </section>
          </v-col>
          <v-col class="polizza__dx">
            <v-row class="pt-0 px-0">
              <!-- <h6 class="text-primary text-uppercase m-0 p-0">
                Caratteristiche Prodotto
              </h6> -->

              <v-data-table :items="garanzie" :headers="garanzieHeader">
              </v-data-table>
            </v-row>
            <section id="tab_provvigionale">
              <polizza-tab-provvigionale v-model="polizza.titolo_attivo" />
            </section>

            <section id="titoli_fatture">
              <VTabs
                height="2rem"
                class="m-0 p-0 mt-3"
                v-model="tabs_titoli_quietanze_appendici_fatture_legate_titoli"
                color="primary"
                dense
                slider-color="primary">
                <VTab ripple> titoli quietanze appendici </VTab>
                <VTab ripple> fatture legate titoli </VTab>
                <VTabItem>
                  <!--   @click:row="setTitoloAttivo" -->
                  <v-data-table
                    :items="titoli"
                    :headers="titoli_headers"
                    dense />
                </VTabItem>
                <VTabItem> fatture </VTabItem>
              </VTabs>
            </section>
          </v-col>
        </v-row>
        <!-- FORM END -->
      </v-card-text>
    </v-card>

    <section v-if="ui.mode == 'EDIT_MODE'">
      <div v-for="(zone, index) in uploads" :key="index">
        
        <dropzone-upload  :options="zone"/>
      </div>
    </section>

  </section>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import Autocomplete from "../ui/Autocomplete.vue";
  import { mapFields } from "vuex-map-fields";
  import PolizzaTabProvvigionale from "./PolizzaTabProvvigionale.vue";
  import TeoDatePicker from "../ui/form/teo-date-picker.vue";
  import TextFormatted from "../ui/form/text-formatted.vue";
import DropzoneUpload from '../ui/form/dropzone-upload.vue';
  export default {
    components: {
      Autocomplete,
      PolizzaTabProvvigionale,
      TeoDatePicker,
      TextFormatted,
        DropzoneUpload,
    },
    mounted() {
      this.$store.dispatch("PolizzaModule/ACTION_GET_FRAZIONAMENTO");

      this.ui.mode = !this.$route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE";
      if (this.ui.mode == "EDIT_MODE") {
        this.$store.dispatch("PolizzaModule/show", this.$route.params.id);
      }
      if (this.ui.mode == "ADD_NEW_MODE") {
        // Create deve resettare il modulo
        this.$store.dispatch("PolizzaModule/create", {});
      }
    },
    data() {
      return {
        tab: 0,

        tabs_premi_provvigioni: 0,
        tabs_titoli_quietanze_appendici_fatture_legate_titoli: 0,
        tabs_caratteristiche_polizza: 0,
        loading: false,

  
        
        // TODO: Forse da spostare nei valori di default
      };
    },
    computed: {
      uploads() {
        return [
          {
            label: "Carta Identità",
            url: "/api/polizza/upload_documenti_polizza",
            maxFiles: 1,
            model_id: this.$route.params.id,
            multiple: false,
            collection:"documenti",
            tag:"carta_identita",
            model:"Polizza"
          },
          {
            label: "Contratto Polizza",
            url: "/api/polizza/upload_documenti_polizza",
            maxFiles: 1,
            multiple: false,
            model_id: this.$route.params.id,
            model: "Polizza",
            collection:"documenti",
            tag:"contratto_polizza"
          },
          // ...aggiungi altre zone di Dropzone qui...
        ]
      },
      ...mapFields("PolizzaModule", [
        "ui",
        "errors",
        "mandato_prodotto_filter",
        "polizza",
        "prodotti",
        "frazionamenti",
        "titoli_headers",
        // "titoli",
        // "rami",
        // "fornitori"
        // "tipo_pagamento",
        // "tipologie_pagamento",
        // "garanzieHeader",
        // "garanzie",
        // "titolo_attivo",
      ]),
      // getCalcoloTotalePremioLordo() {
      //   return this.$store.getters['PolizzaModule/getCalcoloTotalePremioLordo']
      // },
    },
    methods: {
      getCalcoloTotalePremioLordo() {
        const res = Number(this.polizza.competenze)  + Number(this.polizza.totale_premio_imponibile) + Number(this.polizza.totale_premio_imposte) + Number(this.polizza.diritti) + Number(this.polizza.spese)
        //console.log(res,Number(this.polizza.competenze)  , Number(this.polizza.totale_premio_imponibile) , Number(this.polizza.totale_premio_imposte) , Number(this.polizza.diritti) , Number(this.polizza.spese))
        this.polizza.totale_premio_lordo = (Math.round(res * 100) / 100).toFixed(2)
        
      },
      updateRicercaMandatoProdottoPolizza() {
        console.log("updateRicercaMandatoProdottoPolizza",this.mandato_prodotto_filter);
        this.$store.dispatch("PolizzaModule/ACTION_SEARCH_MANDATO_PRODOTTO");
      },
      annulla() {
        // Deve fare il reset del modulo "create"
        this.$store.dispatch("PolizzaModule/annulla");
      },
      ...mapActions("PolizzaModule", [
        "edit",
        "store"
      ]),
      mode() {
        return !this.$route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE";
      },
      aggiungi(polizza){
        console.log("aggiungi",polizza)
        this.store(polizza)
      },
      updateFrazionameto(){

      }
    }
  };
  mapGetters;
</script>

<style>
  .v-card.v-sheet.theme--light {
    background-color: white;
  }
</style>
