// BtnMainSideBarMixin.js
export const StyleComponent = {
  data() {
    return {
      BtnMainSideBar: {
        small: false,
        dark: true,
        color: 'primary',
        outlined: false,
        elevation: 0,
        rounded: false,
        block:true,
        style:{
          "margin-bottom":"0.5rem"
        }
      },
      BtnModalDialog: {
        small: false,
        dark: true,
        color: 'primary',
        outlined: false,
        elevation: 0,
        rounded: false,
        // style:{
        //     width:"auto",
           
        // }
      },
      BtnSmall:{
        small: true,
        dark: true,
        color: 'primary',
        outlined: false,
        elevation: 0,
        rounded: false,
        variant:"plain",
        // style:{
        //     width:"8em",   
        // }
      }
    };
  }
};
