<template>
    <v-data-table
        cy-data="data_table__veicoli_storici"
        :headers="headers_storici"
        :items="veicoli_storici"
        item-key="id"
        show-expand
    >
        <template v-slot:expanded-item="row">
            <td :colspan="headers_storici.length">
                <veicolo-storico-form
                    v-model="row.item"
                    :quotazione="quotazione"
                ></veicolo-storico-form>
            </td>
        </template>
        <template v-slot:[`item.theft`]="{ item }">
            <v-simple-checkbox
                data-cy="data_table__veicolo_storico_checkbox_theft_item.theft"
                v-model="item.theft"
                disabled
            ></v-simple-checkbox>

        </template>
        <template v-slot:[`item.color`]="{ item }">
            <v-chip
                :color="getColor(item.color)"
                dark
                v-if="can('show_quotazioni_suoi_collaboratori') || getColor(item.color) == 'red'"
            >
            </v-chip>
        </template>

        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Veicoli Storici</v-toolbar-title>
            </v-toolbar>
        </template>
        <template v-slot:[`item.ania_type`]="{ item }">
            {{ getNomeByCodice(item.ania_type) }}
        </template>
        <template v-slot:[`item.tax_code`]="{ item }">
            <v-chip
                :color="checkCfisc(item.tax_code)"
                dark
                v-if="item.tax_code"
            >
                {{ item.tax_code }}
            </v-chip>
        </template>
        <template v-slot:[`item.json`]="{ item }">
            <v-dialog
                max-width="1100px"
                v-model="aniaDialog"
                scrollable
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                    > mdi-eye </v-icon>
                </template>
                <v-card>
                    <v-card-title class="text-h5">Dettagli Ania</v-card-title>
                    <v-card-text>
                        <pre>{{ item.json | pretty }}</pre>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="primary"
                            variant="text"
                            @click="aniaDialog = false"
                        >
                            Chiudi
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template v-slot:[`item.state`]="{ item }">
            <v-chip color="blue">{{ item.state }} </v-chip>
        </template>
    </v-data-table>
</template>
<script>
import VeicoloStoricoFormVue from "./VeicoloStoricoForm.vue";

export default {
    components: {
        "veicolo-storico-form": VeicoloStoricoFormVue,
    },
    props: ["value", "veicoli_storici"],
    created() {
        this.quotazione = this.value;
        console.log(this.veicoli_storici);
        //this.fetchVeicoli()
    },

    data: () => ({
        aniaDialog: false,
        quotazione: {},
        headers_storici: [
            {
                text: "",
                value: "color",
            },
            {
                text: "Operazione",
                value: "state",
            },
            {
                text: "Marca",
                value: "brand",
            },
            {
                text: "Modello",
                value: "model",
            },
            {
                text: "Cilindrata",
                value: "displacement",
            },
            {
                text: "Anno",
                value: "year",
            },
            {
                text: "Furto",
                value: "theft",
            },
            {
                text: "Targa",
                value: "plate_number",
            },
            {
                text: "Tipologia ania",
                value: "ania_type",
            },
            {
                text: "Codice Fiscale",
                value: "tax_code",
            },
            { text: "Actions", value: "data-table-expand" },
            {
                text: "Ania",
                value: "json",
            },
        ],
        tipologie_ania: [
            { codice: "A", nome: "Autovettura" },
            { codice: "B", nome: "Autobus" },
            { codice: "C", nome: "Autocarro" },
            { codice: "M", nome: "Motocicli" },
            { codice: "Q", nome: "Motocarri" },
            { codice: "R", nome: "Rimorchi" },
            { codice: "S", nome: "Macchine Agricole" },
            { codice: "T", nome: "Macchine Operatrici" },
            { codice: "W", nome: "Ciclomotori" },
            { codice: "Z", nome: "Quadriciclo" },
        ],
        tipologie_targa: [
            { nome: "Definitiva cee", codice: "N" },
            { nome: "Prova", codice: "R" },
            { nome: "Targhe Speciali", codice: "S" },
            { nome: "Definitiva Vecchia", codice: "V" },
        ],
    }),
    methods: {
        getNomeByCodice(code) {
            const selectedTipologia = this.tipologie_ania.find((item) => item.codice === code);
            return selectedTipologia ? selectedTipologia.nome : "";
        },
        getColor(colore) {
            // console.log(colore);
            if (colore === "verde") return "green";
            else if (colore === "giallo") return "yellow";
            else return "red";
        },
        checkCfisc(cfisc) {
            if (cfisc === this.quotazione.tax_code) return "green";
            else return "red";
        },
        roleIs(role) {
            //console.log(JSON.parse(window.sessionStorage["roles"]).includes(role));
            return JSON.parse(window.sessionStorage["roles"]).includes(role);
        },
    },
    filters: {
        pretty: function (value) {
            return JSON.stringify(JSON.parse(value), null, 2);
        },
    },
    watch: {
        veicoli_storici: {
            handler(new_value) {
                this.$emit("input", new_value);
            },
            deep: true,
        },
    },
};
</script>
