<template>
  <section id="ClienteForm" class="ClienteForm">

    <v-toolbar dense>
      <v-toolbar-title>
        <v-card-title v-if="cliente" class="h4" primary-title>
          <span v-if="!cliente.id">Dati Cliente  {{ mode }}</span>
          <span v-if="cliente.id">{{
            cliente.nome + " " + (cliente.cognome || "")
          }}</span>
        </v-card-title>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- <v-btn
          v-if="mode === 'EDIT_MODE'"
          color="primary"
          class="primary"
          dark
          @click="aggiorna()"
          >Aggiorna</v-btn
        > -->
        <!-- :disabled="loading" -->

 
        <!-- <v-btn color="indigo" dark>Link Three</v-btn> -->
      </v-toolbar-items>
    </v-toolbar>

    <!-- FORM DEL CLIENTE -->
    <v-card v-if="cliente"   class="pa-4">
      <CardAction
       :sezione-attiva="$store.state.sezioneAttiva"
        v-model="cliente.attivo"
        @on-annulla="annulla()"
        @on-aggiorna="edit(cliente)"
        @on-aggiungi="store(cliente)"
        >
      </CardAction>

      <v-card-text>
        <div class="row">
          <!-- INIZIO SLOT COMANDI  -->
          <section id="cliente-app">
            <section class="fieldset" id="cliente__anagrafica">
              <div class="fieldset__content row">
                <!-- Tipo Field -->
               
                <div id="Tipo" class="form-group col-md-2 py-0 px-1 mb-3">
                <label for="tipo">Tipo:</label>
                  <select
                    class="form-control-sm form-control custom-select"
                    id="tipo"
                    v-model="cliente.tipo">
                    <option value="RETAIL">RETAIL</option>
                    <option value="PROFESSIONISTA">PROFESSIONISTA</option>
                    <option value="CORPORATE">CORPORATE</option>
                    <option value="CONDOMINIO">CONDOMINIO</option>
                    <option value="PUBBLICA_AMMINISTRAZIONE">
                      PUBBLICA_AMMINISTRAZIONE
                    </option>
                  </select> 
                </div>
              
                <!-- Codice Field -->
                <!-- <div id="Codice"
                                                                    class="form-group  col-md-2 py-0 px-1 mb-3 ">
                                                                      <label for="codice">Codice:</label>
                                                                      <input class="form-control-sm form-control"
                                                                             v-model="cliente.codice"
                                                                             type="text"
                                                                             id="codice">
                                                               </div> -->

                <!-- Nome Field -->
                <div id="Nome" class="form-group col-md-3 py-0 px-1 mb-3">
                  <label for="nome">Nome:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.nome"
                    type="text"
                    id="nome" />

                  <invalid-feedback :errors="errors.nome" />
                </div>

                <!-- Cognome Field -->
                <div id="Cognome" class="form-group col-md-3 py-0 px-1 mb-3">
                  <label for="cognome">Cognome:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.cognome"
                    type="text"
                    id="cognome" />

                  <invalid-feedback :errors="errors.cognome" />
                </div>

                <!-- Sesso Field -->
                <div id="Sesso" class="form-group col-md-2 py-0 px-1 mb-3">
                  <label for="sesso">Sesso:</label>
                  <select
                    class="form-control-sm form-control custom-select"
                    id="sesso"
                    v-model="cliente.sesso">
                    <option value="M">Maschio</option>
                    <option value="F">Femmina</option>
                    <option value="A">Azienda</option>
                  </select>

                  <invalid-feedback :errors="errors.sesso" />
                </div>
              </div>
            </section>

            <section class="fieldset" id="cliente__anagrafica">
              <div class="fieldset__content row">
                <!-- Comune nascita Field -->
                <div
                  id="comuneNascita"
                  class="form-group col-md-3 py-0 px-1 mb-3">
                   <teo-autocomplete
                     autocomplete="new-password"
                    v-model="cliente.comune_nascita_id"
                    label="Comune di nascita"
                    item-text="nome"
                    item-value="codice"
                    server-side-search
                    resource="/api/autocomplete/comune">
                  </teo-autocomplete>

                  <invalid-feedback :errors="errors.comuneNascita" />
                </div>
                <!-- Nazione nascita Field -->
                <div
                  id="Nazionenascita"
                  class="form-group col-md-3 py-0 px-1 mb-3">
                  <label for="nazioneNascita">Nazione di Nascita:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.nazioneNascita"
                    type="text"
                    id="nazioneNascita" />

                  <invalid-feedback :errors="errors.nazioneNascita" />
                </div>

                <!-- Datanascita Field -->
                <div
                  id="Datanascita"
                  class="form-group col-md-3 py-0 px-1 mb-3">
                  <label for="dataNascita">Data di Nascita:</label>
                  <!-- <input class="form-control-sm form-control"
                                                                             v-model="cliente.dataNascita"
                                                                             type="text"
                                                                             id="dataNascita"> -->

                  <date-picker
                    data-cy="dataNascita"
                    v-model="cliente.dataNascita"
                    value-type="YYYY-MM-DD"
                    format="DD/MM/YYYY">
                  </date-picker>

                  <invalid-feedback :errors="errors.dataNascita" />
                </div>

                <!-- Cf Field -->
                <div id="Cf" class="form-group col-md-3 py-0 px-1 mb-3">
                  <label for="cf">Cf:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.cf"
                    type="text"
                    id="cf" />

                  <invalid-feedback :errors="errors.cf" />
                </div>
              </div>
            </section>

            <section class="fieldset" id="cliente__anagrafica">
              <div class="fieldset__content row">
                <!-- residenzaIndirizzo Field -->
                <div
                  id="residenzaIndirizzo"
                  class="form-group col-md-4 py-0 px-1 mb-3">
                  <label for="residenzaIndirizzo">Indirizzo:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.residenzaIndirizzo"
                    type="text"
                    id="residenzaIndirizzo" />

                  <invalid-feedback :errors="errors.residenzaIndirizzo" />
                </div>
                <div
                  id="residenzaCivico"
                  class="form-group col-md-2 py-0 px-1 mb-3">
                  <label for="residenzaCivico">Civico:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.residenzaCivico"
                    type="text"
                    id="residenzaCivico" />

                  <invalid-feedback :errors="errors.residenzaCivico" />
                </div>

                <div
                  id="residenzaComune"
                  class="form-group col-md-3 py-0 px-1 mb-3">
                  <teo-autocomplete
                    name="comune_residenza"
                    v-model="cliente.residenza_comune_id"
                    label="Comune di residenza"
                    item-text="nome"
                    item-value="codice"
                    server-side-search
                    resource="/api/autocomplete/comune">
                  </teo-autocomplete>

                  <invalid-feedback :errors="errors.residenza_comune_id" />
                </div>


                <!-- residenzaCap Field -->
                <div
                  id="residenzaCap"
                  class="form-group col-md-1 py-0 px-1 mb-3">
                  <label for="residenzaCap">Cap:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.residenzaCap"
                    type="text"
                    id="residenzaCap" />

                  <invalid-feedback :errors="errors.residenzaCap" />
                </div>

                <!-- residenzaProv -->
                <div
                  id="residenzaProv"
                  class="form-group col-md-1 py-0 px-1 mb-3">
                  <label for="residenzaProv">Prov:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.residenzaProv"
                    type="text"
                    id="residenzaProv" />

                  <invalid-feedback :errors="errors.residenzaProv" />
                </div>
              </div>
            </section>

            <section class="fieldset" id="cliente__anagrafica">
              <h3 class="h6 fieldset__title">Documenti identità</h3>
              <div class="fieldset__content row">
                <!-- Documentonumero Field -->
                <div id="Documentonumero" class="form-group col-md-3">
                  <label for="documentoNumero">Numero:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.documentoNumero"
                    type="text"
                    id="documentoNumero" />

                  <invalid-feedback :errors="errors.documentoNumero" />
                </div>

                <!-- Documentotipo Field -->
                <div id="Documentotipo" class="form-group col-md-3">
                  <label for="documentoTipo">Tipo:</label>
                  <select
                    class="form-control-sm form-control custom-select"
                    id="documentoTipo"
                    v-model="cliente.documentoTipo">
                    <option value="CARTA_IDENTITA">CARTA_IDENTITA</option>
                    <option value="PASSAPORTO">PASSAPORTO</option>
                  </select>
                  <invalid-feedback :errors="errors.documentoTipo" />
                </div>
                <!-- Documentoscadenza Field -->
                <div id="Documentoscadenza" class="form-group col-md-3">
                  <label for="documentoScadenza">Scadenza:</label>

                  <!-- <input class="form-control form-control-sm"
                                                                             id="documentoScadenza"
                                                                             v-model="cliente.documentoScadenza"
                                                                             type="text"> -->

                  <date-picker
                    data-cy="documentoScadenza"
                    v-model="cliente.documentoScadenza"
                    value-type="YYYY-MM-DD"
                    format="DD/MM/YYYY">
                  </date-picker>

                  <invalid-feedback :errors="errors.documentoScadenza" />
                </div>

                <!-- documentoRilasciatoDa Field -->
                <div id="documentoRilasciatoDa" class="form-group col-md-3">
                  <label for="documentoRilasciatoDa">Rilasciato da:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.documentoRilasciatoDa"
                    type="text"
                    id="documentoRilasciatoDa" />

                  <invalid-feedback :errors="errors.documentoRilasciatoDa" />
                </div>
              </div>
            </section>
            <section class="fieldset" id="cliente__contatti">
              <h3 class="h6 fieldset__title">contatti</h3>

              <div class="fieldset__content row">
                <!-- Cell Field -->
                <div id="Cell" class="form-group col-md-3">
                  <label for="cell">Cell:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.cell"
                    type="text"
                    id="cell" />

                  <invalid-feedback :errors="errors.cell" />
                </div>

                <!-- Email Field -->
                <div id="Email" class="form-group col-md-3">
                  <label for="email">Email:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.email"
                    type="email"
                    id="email" />

                  <invalid-feedback :errors="errors.email" />
                </div>

                <!-- Pec Field -->
                <div id="Pec" class="form-group col-md-3">
                  <label for="pec">Pec:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.pec"
                    type="email"
                    id="pec" />

                  <invalid-feedback :errors="errors.pec" />
                </div>

                <!-- Tel Field -->
                <div id="Tel" class="form-group col-md-3">
                  <label for="tel">Tel:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.tel"
                    type="text"
                    id="tel" />

                  <invalid-feedback :errors="errors.tel" />
                </div>

                <!-- Referente Field -->
                <div id="Referente" class="form-group col-md-3">
                  <label for="referente">Referente:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.referente"
                    type="text"
                    id="referente" />

                  <invalid-feedback :errors="errors.referente" />
                </div>
              </div>
            </section>

            <section class="fieldset" id="cliente__attivita">
              <h3 class="h6 fieldset__title">attività</h3>
              <div class="fieldset__content row">
                <!-- Attivitasvolta Field -->
                <div id="Attivitasvolta" class="form-group col-md-6">
                  <label for="attivitaSvolta">Attivita svolta:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.attivitaSvolta"
                    type="text"
                    id="attivitaSvolta" />

                  <invalid-feedback :errors="errors.attivitaSvolta" />
                </div>

                <!-- Numerodipendentimaggioredi Field -->
                <div
                  id="Numerodipendentimaggioredi"
                  class="form-group col-md-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cliente.numeroDipendentiMaggioreDi"
                      type="hidden"
                      value="MAGGIORE_DI" />
                    <input
                      class="form-check-input"
                      v-model="cliente.numeroDipendentiMaggioreDi"
                      type="checkbox"
                      value="MINORE_DI" />
                    <label
                      for="numeroDipendentiMaggioreDi"
                      class="form-check-label"
                      >Numero dipendenti maggiore di:</label
                    >
                  </div>
                  <invalid-feedback
                    :errors="errors.numeroDipendentiMaggioreDi" />
                </div>
                <!-- Piva Field -->
                <div id="Piva" class="form-group col-md-6">
                  <label for="piva">Piva:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.piva"
                    type="text"
                    id="piva" />

                  <invalid-feedback :errors="errors.piva" />
                </div>

                <!-- Iban Field -->
                <div id="Iban" class="form-group col-md-6">
                  <label for="IBAN">Iban:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.IBAN"
                    type="text"
                    id="IBAN" />
                  <invalid-feedback :errors="errors.IBAN" />
                </div>

                <!-- Codiceunivocopa Field -->
                <div id="Codiceunivocopa" class="form-group col-md-6">
                  <label for="codiceUnivocoPA">Codiceunivocopa:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.codiceUnivocoPA"
                    type="text"
                    id="codiceUnivocoPA" />

                  <invalid-feedback :errors="errors.codiceUnivocoPA" />
                </div>
              </div>
            </section>

            <section class="fieldset" id="cliente__informazioni_attivita">
              <h3 class="h6 fieldset__title">Pagamento</h3>
              <div class="fieldset__content row">
                <!-- Codicedestinatario Field -->
                <div id="Codicedestinatario" class="form-group col-md-3">
                  <label for="codiceDestinatario">Codicedestinatario:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.codiceDestinatario"
                    type="text"
                    id="codiceDestinatario" />
                  <invalid-feedback :errors="errors.codiceDestinatario" />
                </div>

                <!-- Note Field -->
                <div id="Note" class="form-group col-md-3">
                  <label for="note">Note:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.note"
                    type="text"
                    id="note" />

                  <invalid-feedback :errors="errors.note" />
                </div>

                <!-- Compliance Field -->
                <div id="Compliance" class="form-group col-md-3">
                  <label for="compliance">Compliance:</label>
                  <input
                    class="form-control-sm form-control"
                    v-model="cliente.compliance"
                    type="text"
                    id="compliance" />
                </div>

                <!-- Occupazione Field -->
                <div id="Occupazione" class="form-group col-md-3">
                  <label for="occupazione">Occupazione:</label>
                  <select
                    class="form-control-sm form-control custom-select"
                    id="occupazione"
                    v-model="cliente.occupazione">
                    <option value="DIPENDENTE_PUBBLICO">
                      DIPENDENTE_PUBBLICO
                    </option>
                    <option value="DIPENDENTE_STATALE">
                      DIPENDENTE_STATALE
                    </option>
                    <option value="DIPENDENTE_PRIVATO">
                      DIPENDENTE_PRIVATO
                    </option>
                    <option value="DIPENDENTE_REGIONALE">
                      DIPENDENTE_REGIONALE
                    </option>
                    <option value="PENSIONATO">PENSIONATO</option>
                    <option value="LAVORATORE_AUTONOMO">
                      LAVORATORE_AUTONOMO
                    </option>
                  </select>

                  <invalid-feedback :errors="errors.occupazione" />
                </div>
                <!-- Isfornitore Field -->
                <div id="Isfornitore" class="form-group col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cliente.isfornitore"
                      type="hidden"
                      value="0" />
                    <input
                      class="form-check-input"
                      v-model="cliente.isfornitore"
                      type="checkbox"
                      value="1" />
                    <label for="isfornitore" class="form-check-label"
                      >Isfornitore:</label
                    >
                  </div>

                  <invalid-feedback :errors="errors.isfornitore" />
                </div>
                <!-- Isprospect Field -->
                <div id="Isprospect" class="form-group col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cliente.isprospect"
                      type="hidden"
                      value="0" />
                    <input
                      class="form-check-input"
                      v-model="cliente.isprospect"
                      type="checkbox"
                      value="1" />
                    <label for="isprospect" class="form-check-label"
                      >Isprospect:</label
                    >
                  </div>
                  <invalid-feedback :errors="errors.isprospect" />
                </div>
              </div>
            </section>

            <section class="fieldset" id="cliente__alltro">
              <h3 class="h6 fieldset__title">Altro</h3>
              <div class="fieldset__content row">
                <!-- Comunicazionicommerciali Field -->
                <div id="Comunicazionicommerciali" class="form-group col-md-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cliente.comunicazioniCommerciali"
                      type="hidden"
                      value="0" />
                    <input
                      class="form-check-input"
                      v-model="cliente.comunicazioniCommerciali"
                      type="checkbox"
                      value="1" />
                    <label
                      for="comunicazioniCommerciali"
                      class="form-check-label"
                      >Comunicazioni Commerciali:</label
                    >
                  </div>
                  <invalid-feedback :errors="errors.comunicazioniCommerciali" />
                </div>
                <!-- Mostraallegati Field -->
                <div id="Mostraallegati" class="form-group col-md-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cliente.mostraAllegati"
                      type="hidden"
                      value="0" />
                    <input
                      class="form-check-input"
                      v-model="cliente.mostraAllegati"
                      type="checkbox"
                      value="1" />
                    <label for="mostraAllegati" class="form-check-label"
                      >Mostraallegati:</label
                    >
                  </div>
                  <invalid-feedback :errors="errors.mostraAllegati" />
                </div>
                <!-- Mostrafatture Field -->
                <div id="Mostrafatture" class="form-group col-md-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cliente.mostraFatture"
                      type="hidden"
                      value="0" />
                    <input
                      class="form-check-input"
                      v-model="cliente.mostraFatture"
                      type="checkbox"
                      value="1" />
                    <label for="mostraFatture" class="form-check-label"
                      >Mostrafatture:</label
                    >
                  </div>

                  <invalid-feedback :errors="errors.mostraFatture" />
                </div>
                <!-- Mostrapremi Field -->
                <div id="Mostrapremi" class="form-group col-md-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cliente.mostraPremi"
                      type="hidden"
                      value="0" />
                    <input
                      class="form-check-input"
                      v-model="cliente.mostraPremi"
                      type="checkbox"
                      value="1" />
                    <label for="mostraPremi" class="form-check-label"
                      >Mostrapremi:</label
                    >
                  </div>

                  <invalid-feedback :errors="errors.mostraPremi" />
                </div>
                <!-- Aggiungeresinistri Field -->
                <div id="Aggiungeresinistri" class="form-group col-md-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="cliente.aggiungereSinistri"
                      type="hidden"
                      value="0" />
                    <input
                      class="form-check-input"
                      v-model="cliente.aggiungereSinistri"
                      type="checkbox"
                      value="1" />
                    <label for="aggiungereSinistri" class="form-check-label"
                      >Aggiungere sinistri:</label
                    >
                  </div>
                  <invalid-feedback :errors="errors.aggiungereSinistri" />
                </div>
    
                <!-- Collaboratore Id Field -->

                <div id="collaboratore_id" class="form-group col-md-6">
                  <teo-autocomplete
                    name="cliente_collaboratore_id"
                    v-model="cliente.collaboratore_id"
                    item-value="id"
                    label="collaboratore referente"
                    item-text="label"
                    resource="/api/autocomplete/collaboratore">
                  </teo-autocomplete>

                  <invalid-feedback :errors="errors.collaboratore_id" />
                </div>
              </div>
            </section>
          </section>
          <!-- FINE SLOT COMANDI  -->
        </div>
      </v-card-text>
    </v-card>
    <div :class="{ 'loading-overlay': loading }"></div>
  </section>
</template>

<script>
  import DatePicker from "vue2-datepicker";
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
  import AutocompleteVue from "../ui/Autocomplete.vue";
  import CardAction from "../ui/form/card-action.vue";
  import InvalidFeedback from "../ui/InvalidFeedback.vue";

  // const model = "Cliente";

  export default {
    components: {
      "teo-autocomplete": AutocompleteVue,
      "date-picker": DatePicker,
      "invalid-feedback": InvalidFeedback,
      CardAction,
    },

    mounted() {
      
      if (this.mode == "EDIT_MODE") {
        this.$store.dispatch("ClienteModule/show", this.$route.params.id);
      } 
      if (this.mode == "ADD_NEW_MODE") {
        this.$store.dispatch("ClienteModule/create", {});
      }
    },
    methods: {
      annulla() {
        this.$store.dispatch("ClienteModule/annulla");
      },
      ...mapActions("ClienteModule", ["edit", "store","create"]),
    },
    computed: {
      ...mapFields("ClienteModule", [
        "cliente",
        "loading",
        "errors",
      ]),
      mode() {
        return !this.$route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE";
      }
    },
  };
</script>

<style></style>
