<template>
  <v-container fluid :class="containerPadding">
    <v-alert dense text :type="$route.params.type" v-if="$route.params.type">
      {{ $route.params.message }}
    </v-alert>

    <v-alert :type="flashType" v-if="flashType" dismissible>{{ flashMessage }}</v-alert>

    <v-data-table
      :headers="headers"
      :items="quotazioni"
      :loading="loading"
      :server-items-length="totalItems"
      :options.sync="options"
    >
      <template v-slot:[`header.id`]="{ header }">
        {{ header.text }}
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small> mdi-filter </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field
              v-model="options.id"
              class="pa-4"
              type="text"
              label="Ricerca..."
              :autofocus="true"
            ></v-text-field>
            <v-btn small text color="primary" class="ml-2 mb-2" @click="options.id = ''"
              >Pulisci</v-btn
            >
          </div>
        </v-menu>
      </template>
      <template v-slot:[`header.cognome`]="{ header }">
        {{ header.text }}
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small> mdi-filter </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field
              v-model="options.cognome"
              class="pa-4"
              type="text"
              label="Ricerca..."
              :autofocus="true"
            ></v-text-field>
            <v-btn
              small
              text
              color="primary"
              class="ml-2 mb-2"
              @click="options.cognome = ''"
              >Pulisci</v-btn
            >
          </div>
        </v-menu>
      </template>
      <template v-slot:[`header.email`]="{ header }">
        {{ header.text }}
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small> mdi-filter </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field
              v-model="options.email"
              class="pa-4"
              type="text"
              label="Ricerca..."
              :autofocus="true"
            ></v-text-field>
            <v-btn
              small
              text
              color="primary"
              class="ml-2 mb-2"
              @click="options.email = ''"
              >Pulisci</v-btn
            >
          </div>
        </v-menu>
      </template>
      <template v-slot:[`header.collaboratore`]="{ header }">
        {{ header.text }}
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small> mdi-filter </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-select
              class="pa-4"
              :items="collaboratori"
              label="Collaboratore"
              dense
              v-model="options.collaboratore_id"
              item-value="id"
              item-text="nomeCognome"
            />
            <v-btn
              small
              text
              color="primary"
              class="ml-2 mb-2"
              @click="options.collaboratore_id = ''"
              >Pulisci</v-btn
            >
          </div>
        </v-menu>
      </template>

      <!-- <template v-slot:[`item.premio`]=" {item}"> € {{ item.premio }} </template> -->
      <template v-slot:[`item.decorrenza`]="{ item }">
        <div
          class="td-text-small text-right ma-0 pa-0 text-caption"
          v-if="item.decorrenza"
        >
          {{ formatDate(item.decorrenza) }}
        </div>
        <div class="td-text-small text-right ma-0 pa-0 text-overline" v-if="item.polizza">
          <!-- item.veicoli_storici[0].verti_policy_number -->
          {{ item.polizza?.infodrive_id }}
        </div>

        <div class="td-text-small text-right ma-0 pa-0 text-overline" v-if="item.polizza">
          {{ item.polizza?.verti_policy_number }}
        </div>
      </template>
      <template v-slot:[`item.collaboratore`]="{ item }">
        <span>{{ item.collaboratore.nome }} {{ item.collaboratore.cognome }} </span>
      </template>
      <template v-slot:[`item.provincia`]="{ item }">
        <span>{{ item.provincia.nome }}</span>
      </template>
      <template v-slot:[`item.stato_quotazione`]="{ item }">
        <span>{{ item.stato_quotazione.nome }}</span>
      </template>
      <template v-slot:[`item.premio`]="{ item }">
        <span v-if="item.premio" class="no-wrap"> € {{ item.premio }} </span>
      </template>
      <template v-slot:[`item.premio_forzato`]="{ item }">
        <v-chip
          class="ma-2"
          :color="colorePremioForzato(item)"
          v-if="item.premio_forzato"
        >
          € {{ item.premio_forzato }}
        </v-chip>
        <!-- <br> -->
        <!-- finale € {{ item.premio_finale }}
                   <br> -->
      </template>
      <template v-slot:[`item.colore`]="{ item }">
        <v-chip
          :color="getColor(item.colore)"
          dark
          v-if="
            can('show_quotazioni_suoi_collaboratori') || getColor(item.colore) == 'red'
          "
        >
          !
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
       <div class="d-flex">
         <v-icon
          :data-cy="'editItem_' + item.id"
          small
          @click="editItem(item)"
          class="mr-2"
        >
          mdi-pencil
        </v-icon>
        <!-- {{item.stato_quotazione.id}} -->
        <v-icon
          v-if="item.stato_quotazione.id != 10 && item.stato_quotazione.id != 9"
          :data-cy="'deleteItem_' + item.id"
          small
          @click="deleteItem(item)"
          class="mr-0"
        >
          mdi-delete
        </v-icon>
       </div>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="false && toolbarTitle">titolo</v-toolbar-title>
          <!-- <v-toolbar-title >titolo</v-toolbar-title> -->
          <!-- <v-spacer></v-spacer> -->
          <v-card-title>
            <v-text-field v-model="filterTarga" label="Targa"></v-text-field>
            <VCheckbox
              label="Visualizza Scadenze mese prossimo"
              v-model="options.scade_il_mese_prossimo"
            ></VCheckbox>
         
              <!-- @change="setScadenzaFilter" -->
          </v-card-title>

          <div id="toolbar_action__menu_sx" class="ml-auto">
           
           <!-- AGGIUNGI  QUOTAZIONE DIALOG -->
            <v-btn
              data-cy="aggiungi__onAddNewItem"
              color="primary"
              @click="onAddNewItem()"
            >
              Aggiungi
            </v-btn>
          </div>

    
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Sicuro di voler cancellare la quotazione?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text>Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- END AGGIUNGI  QUOTAZIONE DIALOG -->


        </v-toolbar>

         <!-- FILTRI TOOLBARS -->
        <v-toolbar flat>
          <div class="mr-5">
            <v-badge
              overlap
              :content="allQuotazioni"
              :value="allQuotazioni"
              color="primary"
            >
              <v-chip x-small @click="filterStatus(null)" color="secondary">
                Tutte
              </v-chip>
            </v-badge>
          </div>

           <!-- LOOP FILTRI TOOLBARS -->
          <div
            v-for="stato in stati_quotazione"
            :key="stato.stato_quotazione_id"
            class="mr-5"
          >
            <v-badge overlap :content="stato.count" :value="stato.count" color="primary">
              <v-chip
                x-small
                @click="filterStatus(stato.stato_quotazione_id)"
                color="secondary"
              >
                {{ stato.stato_quotazione.nome }}
              </v-chip>
            </v-badge>
          </div>
        </v-toolbar>
         <!-- END FILTRI TOOLBARS -->

      </template>
    </v-data-table>
  </v-container>
</template>

<script>
//import TableComponent from '../ui/TableComponent.vue';
import { Api, errorCallback } from "@/http_tools/axiosModule";

export default {
  name: "quotazione-page",
  data() {
    return {
      filterTarga: "",
      quotazioni: [],
      loading: true,
      flashType: null,
      flashMessage: null,
      allQuotazioni: null,
      totalItems: 0,
      sortDesc: [],
      sortBy: [],
      options: {
        sort: ["-id"], // Default sort column
        page: 1,
        itemsPerPage: 15,
        stato: "",
        cognome: "",
        email: "",
        collaboratore_id: "",
        id: "",
      },
      stati_quotazione: [],
      headers: [
        { text: "", value: "colore", sortable: false },

        { text: "Id", value: "id", sortable: false },

        { text: "Decorrenza", value: "decorrenza", sortable: false },
        { text: "Collaboratore", value: "collaboratore", sortable: false },
        { text: "Stato", value: "stato_quotazione", sortable: false },
        { text: "CLIENTE ID", value: "cliente_id", sortable: false },
        { text: "Nome", value: "nome", sortable: false },
        { text: "Cognome", value: "cognome", sortable: false },
        // {text: "Targhe", value: "targhe"},
        { text: "Provincia", value: "provincia", sortable: false },
        { text: "Email", value: "email", sortable: false },

        // {text: "Veicoli",value: "veicoli_storici_numero"},
        { text: "Premio", value: "premio", sortable: false },
        { text: "Forzato", value: "premio_forzato", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dialogDelete: false,
    };
  },
  mounted() {},
  methods: {
    colorePremioForzato(item) {
      if (item.premio_forzato > item.premio) return "green";
      if (item.premio_forzato <= item.premio) return "red";
    },
    filterStatus(id) {
      this.options.stato = id;
      this.getItems();
    },
    async fetchStati() {
      var apiURL = process.env.VUE_APP_API_URL + "/api/stati_quotazione";
      await Api.get(apiURL, {})
        .then((response) => {
          this.stati_quotazione = response.data;
          this.allQuotazioni = this.stati_quotazione.reduce(
            (accumulator, transaction) => {
              return accumulator + transaction.count;
            },
            0
          );
        })
        .catch((error) => {
          console.log(error);
          errorCallback(error);
        });
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Adjust the formatting as needed
    },
    ricercaQuotazioni(value, search, item) {
      if (item.targhe) {
        const p = item.targhe.join(" ").toLowerCase();
        // console.log(search);
        const res = p.includes(search.toLowerCase());
        console.log(search, res);
        return res;
      }
    },
    getItems() {
      this.loading = true;

      var apiURL = process.env.VUE_APP_API_URL + "/api/quotazioni";
      Api.get(apiURL, {
        params: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          sort: this.options.sort,
          sortDesc: this.options.sortDesc,
          "filter[scade_il_mese_prossimo]": this.options.scade_il_mese_prossimo,
          "filter[stato_quotazione_id]": this.options.stato,
          "filter[cognome]": this.options.cognome,
          "filter[email]": this.options.email,
          "filter[collaboratore_id]": this.options.collaboratore_id,
          "filter[id]": this.options.id,
        },
      })
        .then((response) => {
          this.quotazioni = response.data.data;
          this.totalItems = response.data.total;
          this.last_page = response.data.last_page;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          errorCallback(error);
        });
    },
    onAddNewItem() {
      this.$router.push("/new/quotazione/");
    },
    getColor(colore) {
      if (colore === "Verde") return "green";
      else if (colore === "Giallo") return "yellow";
      else return "red";
    },
    deleteItem(item) {
      this.editedIndex = this.quotazioni.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.quotazioni.splice(this.editedIndex, 1);
      var apiURL = process.env.VUE_APP_API_URL + "/api/quotazioni/" + this.editedItem.id;
      Api.delete(apiURL)
        .then((response) => {
          response;
          this.flashType = "success";
          this.flashMessage = response.data.message;
        })
        .catch((error) => {
          console.log(error);
          errorCallback(error);
        });
      this.closeDelete();
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      if (item.stato_quotazione.id == 12) {
        this.$router.push({
          name: "quotazione_diretta_edit",
          params: { id: this.editedItem.id },
        });
      } else {
        this.$router.push({
          name: "quotazione_edit",
          params: { id: this.editedItem.id },
        });
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  created: function () {
    this.getItems();
    this.fetchStati();
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.getItems();
      },
    },
  },
  computed: {
    collaboratori() {
      let q = this.quotazioni.map((item) => item.collaboratore);
      q = [...q].sort((a, b) => a.id - b.id);

      return q.map((item) => ({
        ...item,
        numItems: q.length,
      }));
    },
  },
};
</script>

<style>
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Optional: Adds an ellipsis (...) for overflowing text */
}

.td-text-small {
  line-height: 110% !important;
}
</style>
